.SoilWidgetContainer {
  display: flex;
  flex-direction: row;
  width: 25rem;
  min-height: 250px;
  margin: 0 1rem;
  padding: 0 1rem;
  border-radius: 25px;
  background: linear-gradient(90deg, rgb(218 215 205) 10.57%, rgb(88 129 87) 42.51%, rgb(52 78 65) 84.43%);
}

/*.SoilWidgetContainer div {*/
/*  !* border: 0.05rem solid #ffffff;*/
/*    border-radius: 20px; *!*/
/*  margin: 0.5rem;*/
/*}*/

.SWIcon {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.SWDetails {
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SoilWidgetTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  color: rgb(255 255 255);
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.SoilWidgetCaption {
  color: rgb(255 255 255);
  font-family: Raleway, sans-serif;
  font-size: 0.6rem;
  font-weight: 400;
}

.MineralWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

tr {
  height: fit-content;
  margin: 0;
  padding: 0;
}

td {
  height: fit-content;
  margin: 0;
  padding: 0;
}

.MTableCell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: 0;
}

.MineralValue {
  font-family: Lato, sans-serif;
  font-size: 3rem;
  font-weight: 700;
}

.TextInfo {
  color: var(--primaryWhite);
  font-family: Raleway, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
}

.NDetails {
  color: rgb(39 170 225);
}

.KDetails {
  color: rgb(251 176 64);
}

.PDetails {
  color: rgb(43 182 115);
}

.ExtraInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.EITable {
  width: 100%;
  font-family: Raleway, sans-serif;
  font-size: 0.7rem;
  text-align: start;
}

.EIFirstCol {
  color: var(--primaryShade);
  font-weight: 700;
}

.EIValue {
  color: var(--primaryWhite);
  font-weight: 700;
  text-align: end;
}

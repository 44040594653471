.CycleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 25px;

  /* background: radial-gradient(400% 350% at -100% -160%, #000000 39.5%, #032B5A 51.86%, #FD605A 57.97%, #FEA428 61.83%, #F6D77F 70.61%, #4F98C9 79.17%, #032B72 87.96%, #000000 94.94%); */

  background: linear-gradient(90deg, rgb(3 123 198) 6.25%, rgb(3 67 141) 54.17%, rgb(0 0 0 / 100%) 94.94%);
  color: rgb(255 255 255 / 100%);
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.StationTitle {
  margin: 0.25rem 0;
}

.TestText {
  width: 80%;
  margin-top: 0.5rem;
  color: rgb(255 255 255 / 100%);
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.LineChart {
  position: relative;
  width: 50%;
}

.DropdownContainer {
  margin: 0.5rem 0;
}

.Overview {
  text-align: center;
}

.TopBar{
  display:flex;
  flex-direction:row;
}

.TopBarLogo{
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: start;
  flex:1;
}

.TopBarMenu{
  display:flex;
  align-items: center;
  justify-content: end;
  flex:1;
}

.TopBarMenu h5 {
  box-sizing: border-box;
  color: rgb(40 168 74);
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.TBContent{
  margin:0 1rem;
}

.TitleContainer h3 {
  box-sizing: border-box;
  color: rgb(40 168 74);
  font-family: Raleway, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}


/*.ShowAgro{*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  overflow-y: scroll;*/
/*}*/
.FirstRow {
  display: flex;
  flex-direction: row;
  justify-content: start;
  max-width: 100vw;
  min-height: 32rem;
  margin: 1rem;
}

.SecondRow {
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  max-width: 100vw;
  margin: 1rem 0;
}

.PriceRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100vw;
  margin: 1rem 0;
}

.ShowAgro .ShowPrice{
  margin:0;
  padding:0;
}

.SWidgetCont{
  margin:0;
}

/* Kalau media gede bisa di set jadi 30remx30rem */
.MapContainer {
  flex-grow: 2;
  width: 30rem;
  height: 25rem;
  margin: 0 1rem;
  border-radius: 1rem;
}

.WSPanelContainer {
  align-items: center;
  width: 25rem;
  height: 30rem;
  margin: 0 1rem;
  border-radius: 25px;
}

.SthContainer {
  margin: 0 1rem;
  border-radius: 25px;
}

.AmbienceContainer {
  border-radius: 25px;
}

.dummyCont {
  align-items: center;
  width: 30rem;
  height: 30rem;
  border: 2px white solid;
  margin-right: 2rem;
  border-radius: 25px;
}

.ShowPrice{
  margin:1rem;
}

.PriceGraphContainer {
  flex: 1;
  width: available;
  height: 25rem;
  margin-right: 1rem;
  border-radius: 1rem;
}

.PriceWidgetContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.FlexRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.MSTRow{
  display: flex;
  height: 30rem;
  margin-top:3rem;
}

.MSTInfoTitle{
  margin: 1rem;
  color: rgb(255 255 255 / 100%);
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.MSTInfoDescription{
  margin: 1rem;
  color: rgb(255 255 255 / 100%);
  font-family: Raleway, sans-serif;
  font-size: 0.8rem;
  font-weight: 600;

  display: block;

  text-align: justify;
}

.MSTInfoDescTitle{
  margin: 0.3rem 1rem;
}

.MSTInfoDesc{
  margin: 1rem;
  color: rgb(255 255 255 / 100%);
  font-family: Raleway, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;

  text-align: justify;
}

.MSTNodeDescription{
  margin:2rem 1rem;
}

.NodeRow{
  display:flex;
  align-items: center;
  justify-content: start;
  margin:1rem
}
.NodeElement{
  width:4rem;
  display:block;
  align-self: center;
}

.CircleNode{
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.EdgesNode{
  width: 2.5rem;
  height: 0.1rem;
  background-color: #c2c2c2;
  border: 3px solid #c2c2c2;
  opacity: 0.8;
}

.GreenNode{
  background: rgba(0,0,0,0.8);
  border: 4px solid var(--primaryColor);
}

.RedNode{
  background: rgba(0,0,0,0.8);
  border: 4px solid var(--errorSecondary);
}

.WhiteNode{
  background: rgba(0,0,0,0.8);
  border: 4px solid var(--primaryWhite);
}

.LoadingContainer{
  width: 100%;
  height:25rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1025px) {
  .FirstRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100vw;
    margin: 1rem 0;
  }
  .MapContainer{
    position: relative;
  }
  .WSPanelContainer{
    position:relative;
  }
  .SecondRow {
    display: flex;
    flex-direction: column;
    justify-content: start;
    max-width: 100vw;
    margin: 1rem 0;
  }

}

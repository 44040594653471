.WeatherStatsPanel {
  /* background: linear-gradient(90deg, #01428E 37.5%, #02162D 100%); */

  animation-duration: 1s;
  animation-duration: 1s;
  border-radius: 1rem;
  animation-fill-mode: both;
  animation-fill-mode: both;
}

.WeatherStatsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.PanelTitle {
  margin: 0.5rem;
  color: rgb(255 255 255 / 100%);
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.DivLine {
  height: 2px;
  margin: 0.3rem 0.2rem;
  border-radius: 1px;
  opacity: 0.8;
  background-color: white;
}

.InterpIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
}

.WeatherStats {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.WeatherStatsTable {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 80%;
}

.WeatherStatsTable td {
  padding: 0 0.5rem;
}

.WeatherStatsInfo {
  margin: 0.5rem;
}

.CellTitle {
  color: rgb(255 255 255 / 100%);
  font-family: Raleway, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
}

.CellValue {
  color: rgb(255 255 255 / 100%);
  font-family: Lato, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-align: left;
}

.CellCaption {
  color: rgb(255 255 255 / 100%);
  font-family: Raleway, sans-serif;
  font-size: 0.6rem;
  font-weight: 400;
  text-align: left;
}

.TrendTitle {
  color: rgb(255 255 255 / 100%);
  font-family: Lato, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: left;
}

.DateHigh {
  color: rgb(244 82 82);
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.DateLow {
  color: rgb(94 186 233);
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.TempHigh {
  color: rgb(244 82 82);
  font-family: Lato, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

.TempLow {
  color: rgb(94 186 233);
  font-family: Lato, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.TrendHigh {
  color: rgb(244 82 82);
  font-family: Raleway, sans-serif;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.TrendLow {
  color: rgb(94 186 233);
  font-family: Raleway, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
}

.DistStatstable {
  display: block;
  text-align: left;
}

.DistCaption {
  margin-top: 0.3rem;
  padding: 0 0.1rem;
  color: rgb(255 255 255 / 100%);
  font-family: Raleway, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
}

.DistTable {
  width: 100%;
}

.DistCellCaption {
  color: rgb(255 255 255 / 100%);
  font-family: Raleway, sans-serif;
  font-size: 0.6rem;
  font-weight: 400;
  text-align: left;
}

.DistCellValue {
  color: rgb(255 255 255 / 100%);
  font-family: Lato, sans-serif;
  font-size: 0.6rem;
  font-weight: 400;
  text-align: right;
}

.WeatherSeries {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 0.5rem;
  color: rgb(255 255 255 / 100%);
  font-family: Lato, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
}

.WSChartTitle {
  margin-top: 0.5rem;
  color: rgb(255 255 255 / 100%);
  font-family: Raleway, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
}

.StatsPanel {
  width: 25rem;
  height: 30rem;
  transform-style: preserve-3d;
  transition: all 1.5s ease;
}

.spin {
  transform: rotateY(-180deg);
}

.StatsPanelDepth {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateZ(50px) scale(0.98);
  perspective: inherit;
}

.StatsPanelContents {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 25px;
  text-align: center;
}

.PanelSuhu {
  width: 100%;
  height: inherit;
  transform-style: preserve-3d;

  /* background: radial-gradient(400% 350% at -100% -160%, #000000 39.5%, #032B5A 51.86%, #FD605A 57.97%, #FEA428 61.83%, #F6D77F 70.61%, #4F98C9 79.17%, #032B72 87.96%, #000000 94.94%); */
  background: linear-gradient(90deg, rgb(3 123 198) 6.25%, rgb(3 67 141) 54.17%, rgb(0 0 0 / 100%) 94.94%);
}

.PanelKelembapan {
  width: 100%;
  height: inherit;
  transform: rotateY(180deg);
  transform-style: preserve-3d;
  background: linear-gradient(90deg, rgb(1 66 142) 37.5%, rgb(2 22 45) 100%);
}

.WSChartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}

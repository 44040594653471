.TimeWeatherContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
}

.WeatherTitleContainer {
  display: flex;
  flex-direction: column;
  color: white;
}

.WidgetTitle {
  color: rgb(255 255 255 / 100%);
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.WidgetDayTitle {
  color: rgb(255 255 255 / 80%);
  font-family: Lato, sans-serif;
  font-size: 0.6rem;
  font-weight: 400;
}



.WeatherInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.WeatherCaption {
  margin-bottom: 0.2rem;
  color: rgb(255 255 255 / 80%);
  font-family: Lato, sans-serif;
  font-size: 0.6rem;
  font-weight: 400;
}

.WeatherInfoBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-top: 0.3rem;
  padding: 0 0.2rem;
  border-radius: 1.6rem;
  background-color: rgb(0 0 0 / 20%);
}

.nInfo {
  margin: 0.1rem 0.5rem;
  color: rgb(255 255 255 / 100%);
  font-family: Lato, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
}

.WeatherPanel {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.Panel {
  width: 25rem;
  height: 14.5rem;
  margin: 0 auto;
  transform-style: preserve-3d;
  transition: all 1.5s ease;
  border-radius: 25px;
}

.PanelContents {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25rem;
  height: 14.5rem;
  backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 25px;
  text-align: center;
}

.PanelToday {
  transform-style: preserve-3d;

  /* background: radial-gradient(400% 350% at -100% -160%, #000000 39.5%, #032B5A 51.86%, #FD605A 57.97%, #FEA428 61.83%, #F6D77F 70.61%, #4F98C9 79.17%, #032B72 87.96%, #000000 94.94%); */
  background: linear-gradient(90deg, rgb(3 123 198) 6.25%, rgb(3 67 141) 54.17%, rgb(0 0 0 / 100%) 94.94%);
}

.PanelTomorrow {
  transform: rotateX(-180deg);
  transform-style: preserve-3d;
  background: linear-gradient(-90deg, rgb(3 123 198) 6.25%, rgb(3 67 141) 54.17%, rgb(0 0 0 / 100%) 94.94%);
}

.StationTitle {
  color: rgb(255 255 255 / 80%);
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
}

.StationTable {
  width: 100%;
}

.StationInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.2rem;
  color: rgb(255 255 255 / 80%);
  font-family: Lato, sans-serif;
  font-size: 0.6rem;
  font-weight: 400;
}

.PanelDepth {
  display: flex;
  flex-direction: row;
  align-items: center;
  transform: translateZ(50px) scale(0.98);
  perspective: inherit;
}

.rotated {
  transform: rotateX(-180deg);
}

.PanelInfoContainer {
  margin: 0 0.4rem;
}

@media only screen and (max-width: 1000px) {
  .WeatherPanel {
    animation-duration: 1s;
    animation-fill-mode: both;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }

  .PanelContainer {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }

  .Panel {
    width: 20rem;
    height: 14.5rem;
    transform-style: preserve-3d;
    transition: all 1.5s ease;
    border-radius: 25px;
  }

  .PanelContents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25rem;
    height: 14.5rem;
    backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    border-radius: 25px;
    text-align: center;
  }
}

.animated {
  animation-duration: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
}

.bounce {
  animation-name: bounce;
  animation-name: bounce;
}


@import url('https://fonts.googleapis.com/css2?family=Bitter&family=Lato:ital,wght@0,300;1,300;1,400;1,700&family=Raleway:wght@400;700&display=swap');


:root{
    --primary: #28A84A;
    --warning: #ECC30B;
    --danger: #ff6663;
    --success: #28A84A;
    --white: #FFFFFF;
    --dark: #181717;
}

.btn {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    border-radius:25px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.btn:hover{
    transform: translateY(-3px);
    box-shadow: 0px 3px rgba(24, 23, 23, .4);;
}

/* Button Colors and Styles*/

.btn--primary--solid {
    background-color: var(--primary);
    color: var(--white);
    border: none;
}


.btn--warning--solid {
    background-color: var(--warning);
    color: var(--white);
    border: none;
}
.btn--danger--solid {
    background-color: var(--danger);
    color: var(--white);
    border: none;
}
.btn--success--solid {
    background-color: var(--success);
    color: var(--white);
    border: none;
}
.btn--primary--outline {
    background-color: transparent;
    border: 2px solid var(--primary);
    color: var(--primary);
}
.btn--warning--outline {
    background-color: transparent;
    border: 2px solid var(--warning);
    color: var(--warning);
}
.btn--danger--outline {
    background-color: transparent;
    border: 2px solid var(--danger);
    color: var(--danger);
}
.btn--success--outline {
    background-color: transparent;
    border: 2px solid var(--success);
    color: var(--success);
}

/*. Button Size*/

.btn--medium{
    padding: calc(3px + 1vmin) calc(20px + 10vmin);
    font-size: calc(5px + 2vmin);
}

.btn--small{
    padding: 15px 30px;
    font-size: calc(10px + 2vmin);
}
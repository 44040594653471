.MultiLineChart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
    width:inherit;
    border-radius: 25px;
    background: linear-gradient(90deg, rgb(0, 96, 122) 6.25%, rgb(0, 64, 82) 54.17%, rgb(0, 32, 41) 94.94%);
}

.GraphTitle{
    margin: 0.5rem;
    color: rgb(255 255 255 / 100%);
    font-family: Raleway, sans-serif;
    font-size: 1rem;
    font-weight: 400;
}
.MultiLine{
    height:20rem;
    width:available;
}
.DropdownWrapper{



}

.dropdownOptions {
  border-radius: 10px;
}

.selectedArea {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 1.5rem;
  margin: 0.2rem 0.4rem 0;

  padding: 0 0 2px;
  border-radius: 2rem;
  background-color: rgb(0 0 0 / 50%);
  color: var(--primaryWhite);
  font-family: Raleway, sans-serif;
  font-size: 0.7rem;
}

.TableSelected {
  width: 100%;
  margin: 0 0.4rem;
  font-family: Raleway, sans-serif;
  font-size: 0.7rem;
  text-align: start;
}

.SelectedLabel {
  display: flex;
  align-items: start;
  margin-left: 0.4rem;
}

.SelectedIcons {
  margin-right: 0.4rem;
  text-align: end;
}

.ItemContainer {
  border-radius: 25px;
  text-align: center;
}

.item {
  display: flex;
  z-index: 5;
  width: 12.5rem;
  height: fit-content;
  padding: 0.5rem 5px;
  transition: 20ms ease all;
  background-color: rgb(0 0 0 / 50%);
  color: rgb(255 255 255 / 100%);
  font-family: Raleway, sans-serif;
  font-size: 0.7rem;
}

.item:hover {
  background-color: var(--secondaryLight);
  font-weight: bold;
}

.menuHidden {
  visibility: hidden;
}

.dropHidden {
  display: none;
  position: absolute;
}

.dropVisible {
  position: absolute;
  z-index: 5;
  height: 40vmin;
  margin-left: 1rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

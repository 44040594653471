.indexPage{
    display: flex;
    justify-content: center;
    align-items: center;
}

.centerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height:100vh;
}

.centerTitle{
    box-sizing: border-box;
    color: rgb(40 168 74);
    font-family: Raleway, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin: 0.5rem 0;
}


.sumutMapContainer{
    height: 25rem;
    width:40rem;
}


.selectedInfo{
    margin: 1rem;
}

.cellTitle{
    color: rgb(40 168 74);
    font-family: Raleway, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem 1rem 0 0;
}

.cellValue{
    color: rgb(255,255,255);
    font-family: Lato, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    padding: 1rem 0 0 1rem;
}

.button-margin-1rem{
    margin: 1rem;
}

.SensorWeatherMapContainer {
  display: flex;
  flex-direction: row;
}

.mapContainer{
  min-width: 300px;
}

.leaflet-container {
  width: 100%;
  height: 30rem;
  margin-right: 1rem;
  border-radius: 25px;
}

.InfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left:0.75rem;
}

@media only screen and (max-width: 1000px) {
  .SensorWeatherMapContainer {
    display: flex;
    flex-direction: column;
  }

  .leaflet-container {
    width: 25rem;
    height: 25rem;
    border-radius: 25px;
  }

  .InfoWrapper {
    display: flex;
    flex-direction: column;
    position:absolute;
    transform: translateY(26rem);
  }
}

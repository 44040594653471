/*
  The font that will be used in this project is
  Raleway : for headers and lead
  Lato : For data visualization and description
  Bitter : for long text.

  Fonts will be loaded
*/

/* @import url('https://fonts.googleapis.com/css2?family=Bitter&family=Lato:ital,wght@0,300;1,300;1,400;1,700&family=Raleway&display=swap'); */
@import "https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;500;600;700&family=Lato:wght@300;400;700&family=Raleway:wght@200;300;400;500;700&display=swap";
@import "leaflet/dist/leaflet.css";

:root {
  --primaryDarkGreen: rgb(35 147 65);
  --primaryColor: rgb(40 168 74);
  --primaryShade: rgb(52 207 93);
  --primaryLight: rgb(153 231 174);
  --secondaryColor: rgb(6 123 194);
  --secondaryShade: rgb(8 154 243);
  --secondaryLight: rgb(124 203 251);
  --errorPrimary: rgb(255 52 48);
  --errorSecondary: rgb(255 102 99);
  --errorLight: rgb(255 175 174);
  --primaryDark: rgb(21 23 28);
  --primaryBlack: rgb(35 35 35);
  --primaryWhite: rgb(245 245 245);
  --primaryGrey: rgb(97 97 97);
  --secondaryGrey: rgb(188 188 188);
  --fullwidth: 100%;
}

body {
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 1rem;
  overflow-y: scroll;
  background-color: var(--primaryDark);
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div{
  margin:0;
  padding:0;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    monospace;
}

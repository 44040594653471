.MSTCanvas {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30rem;
  border-radius: 20px;

  background: linear-gradient(90deg, rgb(0, 96, 122) 6.25%, rgb(0, 64, 82) 54.17%, rgb(0, 32, 41) 94.94%);

}

div.vis-tooltip {
  height: fit-content;
  opacity: 0.8;
  background-color: var(--primaryWhite);
}

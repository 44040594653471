.AmbPanelWrapper {
  animation-duration: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-fill-mode: both;
}

.AmbPanel {
  width: 25rem;
  height: 250px;
  margin: 0 auto;
  transform-style: preserve-3d;
  transition: all 1.5s ease;
  border-radius: 25px;
  color: rgb(255 255 255 / 100%);
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.AmbPanelContents {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 25px;
  text-align: center;
}

.PanelLine {
  width: 100%;
  height: inherit;
  transform-style: preserve-3d;

  /* background: linear-gradient(90deg, #E6CCB2 2.08%, #B08968 29.17%, #7F5539 69.27%); */

  /* background: linear-gradient(90deg, #A3B18A 10.57%, #588157 42.51%, #344E41 84.43%); */
  background: linear-gradient(90deg, rgb(52 78 65) 5.57%, rgb(88 129 87) 42.51%, rgb(176 137 104) 94.43%);
}

.PanelAmbTitle {
  margin: 1rem 0 0.5rem;
}

.PanelAmbCaption {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: rgb(255 255 255 / 100%);
  font-family: Lato, sans-serif;
  font-size: 0.6rem;
  font-weight: 400;
}

.AmbPanelDepth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25rem;
  height: inherit;
  transform: translateZ(50px) scale(0.98);
  transform-origin: center center;
  perspective: inherit;
}

.PageTurner {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 2rem;
  height: 5rem;
}

.AmbDropdownContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0.5rem;
}

.PanelHist {
  width: 100%;
  height: inherit;
  transform: rotateY(180deg);
  transform-style: preserve-3d;
  background: linear-gradient(90deg, rgb(1 66 142) 37.5%, rgb(2 22 45) 100%);
}

.ambSpinRight {
  transform: rotateY(180deg);
}

.AmbBarChart {
  width: 80%;
  height: 80%;
}

.widget_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  padding: 0.1rem 0.5rem;
  border: 2px solid white;
  border-radius: 20px;
}

.icon_container {
  display: block;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.PriceContainer {
  display: flex;
  flex-direction: row;
}

.PriceContainer h4 {
  margin: 0.1rem 0;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  text-align: start;
}

.ChangeContainer {
  margin: 0.1rem 0;
  color: white;
  font-family: Lato, sans-serif;
  font-size: 0.55rem;
  font-weight: 400;
  text-align: left;
}

.AreaContainer {
  color: white;
  font-family: Lato, sans-serif;
  font-size: 0.5rem;
  font-weight: 400;
  text-align: left;
}

.info_container h4 {
  margin: 0;
}

.details_container {
  margin: 0;
  text-align: start;
}

.details_container h5,
h6 {
  margin: 0;
}

.change_info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.load_canvas {
  display: flex;
  align-items: center;
  justify-content: center;
}

.load_text {
  opacity: 0.5;
  color: rgb(255 255 255);
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.load_logo {
  display: flex;
  box-sizing: border-box;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .load_logo {
    animation: Load-logo-spin infinite 20s linear;
  }
}

@keyframes Load-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1000px) {
  .widget_container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: inherit;
    margin: 0.5rem;
    padding: 0.1rem 0.5rem;
    border: 2px solid white;
    border-radius: 20px;
  }
}
